module.exports = {
  siteTitle: 'DanielCare LLC',
  siteTitleShort: 'DanielCare',
  siteDescription: 'A personal approach to home care.',
  siteUrl: 'https://www.danielcarellc.com/',
  // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  pathPrefix: null,
  company: {
    type: 'LocalBusiness',
    name: 'DanielCare',
    legalName: 'DanielCare, LLC',
    email: 'HomeHealth@danielcare.com',
    address: {
      country: 'United States',
      region: 'Stamford, CT',
      postalCode: '06901',
      streetAddress: '188 North Street',
    },
    telephone: [
      '203-691-7989',
    ],
  },
};
