import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
// import Metadata from '../metadata/metadata';
// import Header from '../header/header';
// import Footer from '../footer/footer';
import '../styles/all.scss'

const Layout = ({ data, children, headerContent, footerContent, className, heroImage }) => (
  <div className={className}>
    {/* <Metadata /> */}
    <header className="site-header" id="site-header">
      {headerContent}
      <nav className="primary">
        <label htmlFor="show-menu-toggle" className="show-menu"><i className="fa fa-bars" aria-hidden="true"></i>Menu</label>
        <input type="checkbox" id="show-menu-toggle" role="button" />
        <ul className="wrapper">
          <li><Link to="/about/" activeClassName="selected">About us</Link></li>
          <li><Link to="/services/" activeClassName="selected">Services</Link></li>
          <li><Link to="/setting-up-care/" activeClassName="selected">Setting up care</Link></li>
          <li><Link to="/testimonials/" activeClassName="selected">Testimonials</Link></li>
          <li><Link to="/contact/" activeClassName="selected">Contact us</Link></li>
        </ul>
      </nav>
    </header>
    <main className="main">
      <div className="wrapper">
        {children}
      </div>
    </main>
    {footerContent && (
      <section className="content-footer">
        <div className="wrapper clearfix">
          {footerContent}
        </div>
      </section>
    )}
    {heroImage && (
      <section className="hero-image">
        <img srcSet={`${heroImage.src1x} 1x, ${heroImage.src2x} 2x`} alt={heroImage.desc}/>
      </section>
    )}
    <footer className="site-footer">
      <div className="wrapper">
        <span className="copyright">© DanielCare, LLC. 2020 All Rights Reserved.</span>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/employment/">Application for Employment</Link></li>
            <li><Link to="/contact/">Contact us</Link></li>
          </ul>
        </nav>
      </div>
    </footer>
  </div>
);

Layout.propTypes = {
  // data: PropTypes.shape({
  //   site: PropTypes.object.isRequired,
  // }).isRequired,
  children: PropTypes.node.isRequired,
  headerContent: PropTypes.node,
  className: PropTypes.string,
  heroImage: PropTypes.shape({
    src1x: PropTypes.string.isRequired,
    src2x: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
  })
};

export default Layout;
